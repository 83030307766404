<template>
  <div class="page-main-bg">
    <v-container class="main-container">
      <!-- Tool-Bar -->
      <tool-bar v-bind="toolBarProps" />

      <!-- Filters -->
      <ApprovalFilter
        :approvalConfig="aprrovalFilters"
        @updateFilters="updateFilters"
      />
      <!-- Loader -->
      <loader v-if="isPageLoading" v-bind="loaderProps" />

      <!-- no Approvals -->
      <no-data v-else-if="!approvals?.length" v-bind="noDataProps" />
      <!-- Approval Table -->
      <ApprovalDataTable
        v-else-if="approvals.length"
        :approvals="approvals"
        @openDetails="openDetails"
        @show-details="openDetails"
        @accept="handleApproveAction"
        @reject="handleApproveAction"
      />
      <!-- Side Details -->
      <v-layout v-show="sideDetailes">
        <ApprovalSideDetails
          :isSideDetailsLoading="isSideDetailsLoading"
          :approval="selectedApproval"
          @closeDetails="closeDetails"
          @approved="handleSideDetailsAction"
          @rejected="handleSideDetailsAction"
          @cancelled="handleSideDetailsAction"
          ref="sideDetails"
        />
      </v-layout>
      <div class="text-center" v-if="pagination.pagesCount > 1">
        <Pagination :pagination="pagination" @onChange="loadAllApprovals" />
      </div>
    </v-container>
  </div>
</template>

<script>
import ToolBar from '@/components/listing/ToolBar'
import Loader from '@/components/helper/loader.vue'
import ApprovalDataTable from '@/components/approval/ApprovalDataTable.vue'
import noData from '@/components/helper/noData.vue'
import Pagination from '@/components/helper/pagination.vue'
import ApprovalFilter from '@/components/approval/ApprovalFilter.vue'
import { userService } from '@/services'
import { popUpBodyTemplates, Approval } from '@/constans/approval'
import { mapState, mapMutations } from 'vuex'
import { buildQueryString } from '@/utils/general'

export default {
  components: {
    ToolBar,
    Loader,
    noData,
    Pagination,
    ApprovalFilter,
    ApprovalDataTable,
    ApprovalSideDetails: () => import('@/components/approval/SideDetails')
  },
  data() {
    return {
      isPageLoading: false,
      sideDetailes: false,
      isSideDetailsLoading: false,
      resonForReject: '',
      selectedApproval: {},
      operationDetails: {},
      filterQuery: {},
      pdfStatue: false,
      approvals: [],
      ...Approval
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    loaderProps() {
      return {
        numberOfLines: 5,
        laoderClasses: 'property-col mb-2 shadow-border-radius px-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    },
    queryParams() {
      const selectedPagination = this.pagination
      return buildQueryString({
        pageSize: selectedPagination.pageSize,
        pageNumber: selectedPagination.pageNumber,
        ...(this.filterQuery.status && { status: this.filterQuery.status }),
        ...(this.filterQuery.type && { type: this.filterQuery.type }),
        ...(this.filterQuery.action && { action: this.filterQuery.action }),
        ...(this.filterQuery.realEstateName && {
          realEstateName: this.filterQuery.realEstateName
        })
      })
    },
    noDataProps() {
      return {
        noDataText: this.noDataConfig.text,
        noDataSubTitle: this.noDataConfig.subTitle,
        noDataIcon: this.noDataConfig.icon
      }
    },
    toolBarProps() {
      return {
        breadcrumbs: this.breadcrumbs,
        title: 'طلبات الموافقات '
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeDetails() {
      this.sideDetailes = false
    },
    handleSideDetailsAction({ item }) {
      this.handleApproveAction({ status: item.status, item })
    },
    handleApproveAction({ item, status }) {
      const popUpBody = popUpBodyTemplates.find(
        (body) => body.status === status
      )
      this.$root.$confirm.open(popUpBody).then(async (confirm) => {
        if (confirm) {
          if (status === 'rejected') {
            const { reason } = this.$root.$confirm.$refs.component
            this.resonForReject = reason
          }
          await this.updateApproval({
            status,
            item,
            ...(this.resonForReject && { reason: this.resonForReject })
          })
          this.resonForReject = null
        }
      })
    },
    updateFilters(filters) {
      this.filterQuery = filters
      this.pagination.pageNumber = 1
      this.loadAllApprovals()
    },
    openOperationSideView(item) {
      this.operationDetails = item
      this.pdfStatue = true
      this.sideDetailes = !this.pdfStatue
    },
    closeOperationSideView() {
      // back to side details
      this.pdfStatue = false
      this.sideDetailes = !this.pdfStatue
    },
    recalculatePagination(res) {
      this.pagination.pagesCount = Math.ceil(
        res.count / this.pagination.pageSize
      )
      this.pagination.count = res.count
    },
    async openDetails(item) {
      try {
        this.isSideDetailsLoading = true
        this.sideDetailes = true
        await this.getSingleApproval(item._id, item.canApprove)
      } catch {
        this.addNotification({
          color: 'error',
          text: 'لم نتمكن من تحميل الموافقة بشكل صحيح'
        })
      } finally {
        this.isSideDetailsLoading = false
      }
    },
    async loadAllApprovals() {
      this.isPageLoading = true
      try {
        const param = {
          envId: this.currentEnv?._id
        }
        const { data } = await userService.getApprovals(param, this.queryParams)
        this.recalculatePagination(data)
        this.approvals = data.approvals
      } catch {
        this.addNotification({
          color: 'error',
          text: 'لم نتمكن من تحميل الموافقات بشكل صحيح'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    async getSingleApproval(approvalId, canApprove) {
      try {
        const param = {
          envId: this.currentEnv?._id,
          approvalId
        }
        const {
          data: { approval }
        } = await userService.getSingleApproval(param)
        this.selectedApproval = { ...approval, canApprove }
      } catch {
        this.addNotification({
          color: 'error',
          text: 'لم نتمكن من تحميل الموافقة بشكل صحيح'
        })
      }
    },
    async updateApproval(param) {
      try {
        this.isSideDetailsLoading = true
        const body = {
          environment: this.currentEnv?._id,
          id: param.item._id,
          status: param.status,
          ...(param.status === 'rejected' && { reason: param.reason })
        }
        await userService.updateApproval(body)
        await this.getSingleApproval(param.item._id)
        await this.loadAllApprovals()
        this.addNotification({
          color: 'success',
          text: 'تم تحديث الموافقة بنجاح'
        })
      } catch {
        this.addNotification({
          color: 'error',
          text: 'لم نتمكن من تحديث الموافقة بشكل صحيح'
        })
      } finally {
        this.isSideDetailsLoading = false
      }
    }
  },
  mounted() {
    this.loadAllApprovals()
  }
}
</script>
